import service from '@/utils/request'; // @Summary 设置角色资源权限
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body sysModel.SysAuthority true "设置角色资源权限"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"设置成功"}"
// @Router /authority/setDataAuthority [post]

export var findFile = function findFile(params) {
  return service({
    url: "/fileUploadAndDownload/findFile",
    method: 'get',
    params: params
  });
};
export var breakpointContinueFinish = function breakpointContinueFinish(params) {
  return service({
    url: "/fileUploadAndDownload/breakpointContinueFinish",
    method: 'post',
    params: params
  });
};
export var removeChunk = function removeChunk(data, params) {
  return service({
    url: "/fileUploadAndDownload/removeChunk",
    method: 'post',
    data: data,
    params: params
  });
};